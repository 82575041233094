import { Loader } from '@googlemaps/js-api-loader';
import type { ReactNode } from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

export const MapsAPIContext = createContext<google.maps.MapsLibrary | null>(null);

export function GoogleMapsAPIProvider({ apiKey, children }: { apiKey: string; children: ReactNode }) {
  const [mapsLibrary, setMapsLibrary] = useState<google.maps.MapsLibrary | null>(null);

  const loadMapsLibrary = useCallback(() => {
    const loader = new Loader({
      apiKey,
      version: 'weekly',
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
    loader.importLibrary('maps').then((library) => {
      setMapsLibrary(() => library);
    });
  }, [apiKey]);

  useEffect(() => {
    if (!mapsLibrary) {
      loadMapsLibrary();
    }

    const reloadInterval = setInterval(() => {
      loadMapsLibrary();
    }, TWENTY_FOUR_HOURS);

    return () => clearInterval(reloadInterval);
  }, [mapsLibrary, loadMapsLibrary]);

  return <MapsAPIContext.Provider value={mapsLibrary}>{children}</MapsAPIContext.Provider>;
}
